@tailwind base;
@tailwind components;
@tailwind utilities;

#app-workspace::-webkit-scrollbar {
    width: 1em;
}
#app-workspace::-webkit-scrollbar-track {
    /* background-color: #eff6ff; */
    background-color: #F3F4F6;
    border:none;
}
#app-workspace::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
#app-ui::-webkit-scrollbar {
    width: 1em;
}
#app-ui::-webkit-scrollbar-track {
  /* background-color: #eff6ff; */
  background-color: #F3F4F6;
  border:none;
}
#app-ui::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.issue-editor::-webkit-scrollbar {
    width: 1em;
}
.issue-editor::-webkit-scrollbar-track {
    /* background-color: #eff6ff; */
    background-color: #bfdbfe;
    border:none;
}
.issue-editor::-webkit-scrollbar-thumb {
  background-color: #2563eb;
  border-radius: 2px;
}

@media (min-width: 1025px) {
  .hero {
    height: calc(100vh - 72px)
  }
}

#request {
  height: calc(100vh - 72px)
}

@layer base {
  .highlight td.bg-gray-200{
    @apply bg-green-200;
  }
}

div.row:hover {
  background-image: url('images/option.png');
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 99% 50%;
}

div.row.select,
div.row.select:hover {
  background-color: #F3F4F6;
  background-image:none;
  -webkit-box-shadow: 0px 3px 10px 6px rgba(0,0,0,0.25);
  box-shadow: 0px 3px 10px 6px rgba(0,0,0,0.25);
}

@keyframes changeLetter {
  0% {
    content: "Running audits...";
  }
  25% {
    content: "Applying algorithm...";
  }
  50% {
    content: "Creating theme...";
  }
  75% {
    content: "Writing epics...";
  }
  100% {
    content: "Generating tasks...";
  }
}
.loading::after {
    animation: changeLetter 15s linear infinite alternate;
    content: "Testing...";
}

body.development .blur {
  filter: blur(4px);
  -webkit-filter: blur(4px);
}

/* CHECKBOX TOGGLE SWITCH */
/* @apply rules for documentation, these do not work as inline style */
.toggle-checkbox:checked {
  /*
  @apply: right-0 border-green-400;
  border-color: #65A30D;
  */
  right: 0;

}
.toggle-checkbox:checked + .toggle-label {
  /*
  @apply: bg-green-400;
  background-color: #65A30D;
  */
}

.guide-item--header {
  background-image: url('images/right-min.svg');
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 0% 9px;
}

.expanded.guide-item--header{
  background-image: url('images/down-min.svg');
}

.guide-item--problem ul{
  margin:1rem 0;
}
.guide-item--problem ul li{
  list-style: disc;
  margin-left: 2rem;
}
